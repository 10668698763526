<template>
    <div>
        <ActualizarEmpresas :tipoBd="'CONTA'" />

        <CRow>
            <CCol>
                <CCard class="tarjetaCV mt-2">
                    <CCardHeader>
                        <h4>Informe de Contabilización</h4>
                    </CCardHeader>
                    <CCardBody>
                        <CRow>
                            <CCol md="2">
                                <multiselect
                                    class="mb-2"
                                    v-model="compraVentaSelected"
                                    selectedLabel=""
                                    deselectLabel=""
                                    selectLabel=""
                                    placeholder="Seleccionar Compra/Venta" 
                                    label="nombre"
                                    track-by="id" 
                                    :options="comprasVentas" 
                                    :multiple="false"
                                    @select="seleccionarCompraVenta">
                                </multiselect>
                                <!--<CIcon :content="$options.freeSet.cilClone" /> -->
                            </CCol>
                            <CCol md="4">
                                <multiselect
                                    class="mb-2"
                                    :custom-label="customLabelEmp"
                                    v-model="empSelected"
                                    selectedLabel=""
                                    deselectLabel=""
                                    selectLabel=""
                                    placeholder="Seleccionar Empresa" 
                                    label="nombreEmpresa"
                                    :loading="isLoadingEmpresasInfo"
                                    track-by="codEmpresa" 
                                    :options="empresasInfo" 
                                    :multiple="false"
                                    @select="seleccionarEmpresa">
                                </multiselect>
                            </CCol>
                             <CCol md="3">
                                <date-picker
                                    style="width: 100%"
                                    :disabled="!empSelected"
                                    :editable="false"
                                    :clearable="false"
                                    :format="'YYYY'"
                                    default-panel="year"
                                    type="year"
                                    class="mb-2"
                                    :lang="langPicker" 
                                    v-model="anioSelected"
                                    :confirm="false" 
                                    :confirm-text="'Aceptar'"
                                    :disabled-date="disabledAnio"
                                    @change="seleccionarAnio">
                                </date-picker>
                            </CCol>
                            <CCol md="3">
                                <date-picker
                                    :default-value="anioObjSelected!==null? new Date(anioObjSelected.anio, 1, 1) : null"
                                    style="width: 100%"
                                    :disabled="!empSelected"
                                    :editable="false"
                                    :format="'MMMM'"
                                    default-panel="month"
                                    type="month"
                                    class="mb-2"
                                    :lang="langPicker" 
                                    v-model="mesSelected"
                                    :confirm="false" 
                                    :confirm-text="'Aceptar'"
                                    :disabled-date="disabledMes2"
                                    @change="seleccionarMes">
                                </date-picker>
                            </CCol>
                        </CRow>
                            <CRow>
                                
                            <CCol md="3">
                                 <multiselect
                                    class="mb-2"
                                    v-model="tipoCtaSelected"
                                    selectedLabel=""
                                    deselectLabel=""
                                    selectLabel=""
                                    placeholder="Seleccionar Tipo Cuenta" 
                                    label="nombre"
                                    track-by="id" 
                                    :options="tipoCuenta" 
                                    :multiple="false"
                                    @select="seleccionarTipoCta">
                                </multiselect>
                            </CCol>
                                
                            <CCol md="3">
                                 <multiselect
                                    class="mb-2"
                                    v-model="tipoOrdenSelected"
                                    selectedLabel=""
                                    deselectLabel=""
                                    selectLabel=""
                                    placeholder="Seleccionar Agrupación" 
                                    label="nombre"
                                    track-by="id" 
                                    :options="tiposOrden" 
                                    :multiple="false"
                                    @select="seleccionarTipoOrden">
                                </multiselect>
                            </CCol>
                            <CCol md="4"><!--filtrosGenerales-->
                                <multiselect
                                    class="mb-2"
                                    v-model="filtrosSelected" 
                                    :options="filtrosEmpresa" 
                                    select-label=""
                                    selectedLabel=""
                                    deselect-label=""
                                    selectGroupLabel=""
                                    deselectGroupLabel=""
                                    :multiple="true" 
                                    group-values="opcionesFiltro" 
                                    group-label="filtroNombre" 
                                    :group-select="true" 
                                    placeholder="Filtros Generales" 
                                    track-by="unico"
                                    label="nombre"
                                    :show-labels="true"
                                    :loading="isLoadingFiltros"
                                    @input="actualizarFiltro">
                                    
                                    <span slot="noResult">Oops! no se encuentran filtros con esa palabra</span>
                                    <template slot="tag" slot-scope="props">
                                        <span class="multiselect__tag">
                                            <span>({{props.option.padre}}) {{props.option.nombre}}</span> 
                                            <i aria-hidden="true" tabindex="1" class="multiselect__tag-icon" @click="quitarTagFiltro(props.option)"></i>
                                        </span>
                                    </template>
                                    <template slot="option" slot-scope="props">
                                        <span v-if="'$groupLabel' in props.option"><span class="text-capitalize font-weight-bold">{{props.option.$groupLabel}}</span> (Seleccionar/quitar todos)</span>
                                        <span v-else>{{props.option.nombre}}</span>
                                    </template>
                                </multiselect>
                            </CCol>
                            
                        </CRow>
                        <CRow>
                            <CCol md="4">
                                <multiselect
                                    class="multSelectGesto mb-2"
                                    v-model="rutSelected" 
                                    :options="ruts" 
                                    select-label=""
                                    selectedLabel=""
                                    deselect-label=""
                                    selectGroupLabel=""
                                    deselectGroupLabel=""
                                    :multiple="true" 
                                    placeholder="Filtrar Rut" 
                                    track-by="rut"
                                    :custom-label="customLabelTrabajador"
                                    :loading="isLoadingTrabajadores"
                                    :show-labels="true"
                                    @input="actualizarFiltroRuts">
                                    <template slot="tag" slot-scope="props">
                                        <span class="multiselect__tag">
                                            <span>{{formatearRut(props.option.rut)}}</span> 
                                            <i aria-hidden="true" tabindex="1" class="multiselect__tag-icon" @click="quitarTag(props.option)"></i>
                                        </span>
                                    </template>
                                </multiselect>
                            </CCol>
                            
                            <CCol md="4" v-if="cuentasFiltro != null">
                                <multiselect
                                    :v-show="cuentasFiltro.mostrarFiltro"
                                    class="multSelectGesto mb-2"
                                    v-model="cuentasSelected" 
                                    :options="cuentasFiltro.filtros" 
                                    select-label=""
                                    selectedLabel=""
                                    deselect-label=""
                                    selectGroupLabel=""
                                    deselectGroupLabel=""
                                    :multiple="true" 
                                    placeholder="Filtrar Cuentas" 
                                    track-by="cuenta"
                                    :custom-label="customLabelCuenta"
                                    :loading="isLoadingCuentasFiltro"
                                    :show-labels="true"
                                    @input="actualizarFiltroCuentas">
                                    <template slot="tag" slot-scope="props">
                                        <span class="multiselect__tag">
                                            <span>{{props.option.cuenta}}</span> 
                                            <i aria-hidden="true" tabindex="1" class="multiselect__tag-icon" @click="quitarTagCuenta(props.option)"></i>
                                        </span>
                                    </template>
                                </multiselect>
                            </CCol>
                            <CCol md="3" v-if="numDocs != null">
                                <multiselect
                                    :v-show="numDocs.mostrarFiltro"
                                    class="multSelectGesto mb-2"
                                    v-model="numDocsSelected" 
                                    :options="numDocs.filtros" 
                                    select-label=""
                                    selectedLabel=""
                                    deselect-label=""
                                    selectGroupLabel=""
                                    deselectGroupLabel=""
                                    :multiple="true" 
                                    placeholder="Filtrar Documento" 
                                    track-by="numDoc"
                                    label="numDoc"
                                    :loading="isLoadingNumDocFiltro"
                                    :show-labels="true"
                                    @input="actualizarFiltroNumDoc">
                                    <template slot="tag" slot-scope="props">
                                        <span class="multiselect__tag">
                                            <span>{{props.option.numDoc}}</span> 
                                            <i aria-hidden="true" tabindex="1" class="multiselect__tag-icon" @click="quitarTagNumDoc(props.option)"></i>
                                        </span>
                                    </template>
                                </multiselect>
                            </CCol>
                            <CCol md="3" v-if="filtrosCR != null">
                                <multiselect
                                    v-show="filtrosCR.mostrarFiltro"
                                    class="multSelectGesto mb-2"
                                    v-model="filtrosCRSelected" 
                                    :options="filtrosCR.filtros" 
                                    select-label=""
                                    selectedLabel=""
                                    deselect-label=""
                                    selectGroupLabel=""
                                    deselectGroupLabel=""
                                    :multiple="true" 
                                    placeholder="Filtrar CR" 
                                    track-by="codigo"
                                    :custom-label="customLabelCR"
                                    :loading="isLoadingCRFiltro"
                                    :show-labels="true"
                                    @input="actualizarFiltroCR">
                                    <template slot="tag" slot-scope="props">
                                        <span class="multiselect__tag">
                                            <span>{{props.option.codigo}}</span> 
                                            <i aria-hidden="true" tabindex="1" class="multiselect__tag-icon" @click="quitarTagCR(props.option)"></i>
                                        </span>
                                    </template>
                                </multiselect>
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol md="12">
                                <div class="p-1">
                                    <CCardGroup class="mb-4 tarjetaTotales" v-if="totales != null" v-show="!isLoadingInfoCV">
                                            <CWidgetProgress v-show="totales.totalMonto!=0">
                                                <div class="m-0 text-center font-weight-bold">{{validarNegativos(totales.totalMonto, true)}}</div>
                                                <div class="text-center text-muted">Total General</div>
                                            </CWidgetProgress>
                                    </CCardGroup>
                                    <CRow v-if="isLoadingInfoCV">
                                        <CCol md="12">
                                            <center>
                                                <img src="/img/logo2.png" size="custom-size" :height="50" class="imgr mt-3 mb-3" /> Obteniendo Información...
                                            </center>
                                        </CCol>
                                    </CRow>
                                    <CRow v-else v-show="cvInfo.length > 0">
                                        <CCol md="12">
                                            <CRow v-for="cv in cvInfo" v-bind:key="cv.key">
                                                <CCol sm="12" md="12" lg="12">
                                                    <CCard accent-color="secondary" class="mt-1 mb-1" style="overflow-x:auto">
                                                        <CCardHeader>
                                                            <CLink class="card-header-action btn-minimize float-left" @click="cv.colapsed = !cv.colapsed">
                                                                <CIcon :name="`cil-chevron-${cv.colapsed ? 'bottom' : 'top'}`"/>
                                                            </CLink>
                                                            <CRow v-if="tipoOrdenSelected.id == 1">
                                                                <CCol md="6">
                                                                    {{cv.cuenta}} {{cv.descripcion}}
                                                                </CCol>
                                                                <CCol md="6">
                                                                    <CRow class="align-items-end">
                                                                        <CCol></CCol>
                                                                        <!--<CCol col="2" v-show="cv.totalOtrosImpuestos > 0"><div  class="ml-2 mr-2 text-muted float-right">Otr. Imps {{validarNegativos(cv.totalOtrosImpuestos, true)}}</div></CCol>
                                                                        <CCol col="2" v-show="cv.mostrarTotalIva && cv.totalIva > 0"><div class="ml-2 mr-2 text-muted float-right">Iva Total {{validarNegativos(cv.totalIva, true)}}</div></CCol>-->
                                                                        <CCol col="3"><div class="ml-1 mr-1 text-muted float-right">Monto Total {{validarNegativos(cv.totalGeneralMonto, true)}}</div></CCol>
                                                                    </CRow>
                                                                </CCol>
                                                            </CRow>
                                                            <CRow v-if="tipoOrdenSelected.id == 2">
                                                                <CCol md="8">
                                                                    <CRow>
                                                                        <CCol col="3"><div class="ml-1 mr-1 text-muted float-right">T. Doc. {{cv.tipoDoc}} </div></CCol>
                                                                        <CCol col="3"><div class="ml-1 mr-1 text-muted float-right">N° Doc. {{cv.numDoc}}</div></CCol>
                                                                        <CCol col="4"><div class="ml-1 mr-1 text-muted float-right elipsisTexto">({{cv.rut}}) {{cv.nombre}}</div></CCol>
                                                                        <CCol col="2"><div class="ml-1 mr-1 text-muted float-right">Corr {{cv.correl}}</div></CCol>
                                                                    </CRow>
                                                                </CCol>
                                                                <CCol md="4">
                                                                    <CRow class="align-items-end">
                                                                        <CCol></CCol>
                                                                        <!--<CCol col="2" v-show="cv.totalOtrosImpuestos > 0"><div  class="ml-2 mr-2 text-muted float-right">Otr. Imps {{validarNegativos(cv.totalOtrosImpuestos, true)}}</div></CCol>
                                                                        <CCol col="2" v-show="cv.mostrarTotalIva && cv.totalIva > 0"><div class="ml-2 mr-2 text-muted float-right">Iva Total {{validarNegativos(cv.totalIva, true)}}</div></CCol>-->
                                                                        <CCol col="5"><div class="ml-1 mr-1 text-muted float-right">Monto Total {{validarNegativos(cv.totalGeneralMonto, true)}}</div></CCol>
                                                                    </CRow>
                                                                </CCol>
                                                            </CRow>
                                                            <CRow v-if="tipoOrdenSelected.id == 3">
                                                                <CCol md="5">
                                                                    <CRow>
                                                                        <CCol col="3"><div class="ml-1 mr-1 text-muted">{{cv.cr}} </div></CCol>
                                                                    </CRow>
                                                                </CCol>
                                                                <CCol md="7">
                                                                    <CRow class="align-items-end">
                                                                        <CCol></CCol>
                                                                        <!--<CCol col="2" v-show="cv.totalOtrosImpuestos > 0"><div  class="ml-2 mr-2 text-muted float-right">Otr. Imps {{validarNegativos(cv.totalOtrosImpuestos, true)}}</div></CCol>
                                                                        <CCol col="2" v-show="cv.mostrarTotalIva && cv.totalIva > 0"><div class="ml-2 mr-2 text-muted float-right">Iva Total {{validarNegativos(cv.totalIva, true)}}</div></CCol>-->
                                                                        <CCol col="3"><div class="ml-1 mr-1 text-muted float-right">Monto Total {{validarNegativos(cv.totalGeneralMonto, true)}}</div></CCol>
                                                                    </CRow>
                                                                </CCol>
                                                            </CRow>
                                                            
                                                        </CCardHeader>
                                                        <CCollapse :show="cv.colapsed">
                                                            <div style="max-height:400px; overflow:hidden; overflow-x:scroll; overflow-y:scroll;" >
                                                                <CCardBody >
                                                                    <table class="tableGesto mt-2">
                                                                        <thead>
                                                                            <th v-for="col in columnasContabilizacion" 
                                                                                v-bind:key="col.scopedSlots.customRender" 
                                                                                :style="{'text-align': col.align, 'width': col.width}">
                                                                                {{col.title}}
                                                                            </th>
                                                                        </thead>
                                                                        <tbody v-if="tipoOrdenSelected.id == 1">
                                                                            <tr v-for="doc in cv.documentos" v-bind:key="doc.numero">
                                                                                <td style="text-align: left;">{{doc.fecha}}</td>
                                                                                <td style="text-align: left;">{{doc.rut}}</td>
                                                                                <td style="text-align: left;">{{doc.nombre}}</td>
                                                                                <td style="text-align: center;">{{doc.tipoDoc}}</td>
                                                                                <td style="text-align: center;">{{doc.numDoc}}</td>
                                                                                <td style="text-align: center;">{{doc.correl}}</td>
                                                                                <td style="text-align: left;">{{doc.cr}}</td>
                                                                                <td style="text-align: left;">{{doc.glosa}}</td>
                                                                                <td style="text-align: right;">{{validarNegativos(doc.monto)}}</td>
                                                                            </tr>
                                                                        </tbody>
                                                                        <tbody v-if="tipoOrdenSelected.id == 2">
                                                                            <tr v-for="doc in cv.documentos" v-bind:key="doc.numero">
                                                                                <td style="text-align: left;">{{doc.fecha}}</td>
                                                                                <td style="text-align: left;">{{doc.cuenta}}</td>
                                                                                <td style="text-align: left;">{{doc.descripcion}}</td>
                                                                                <td style="text-align: left;">{{doc.glosa}}</td>
                                                                                <td style="text-align: left;">{{doc.cr}}</td>
                                                                                <td style="text-align: right;">{{validarNegativos(doc.monto)}}</td>
                                                                            </tr>
                                                                        </tbody>
                                                                        <tbody v-if="tipoOrdenSelected.id == 3">
                                                                            <tr v-for="doc in cv.documentos" v-bind:key="doc.numero">
                                                                                <td style="text-align: left;">{{doc.rut}}</td>
                                                                                <td style="text-align: left;">{{doc.nombre}}</td>
                                                                                <td style="text-align: center;">{{doc.tipoDoc}}</td>
                                                                                <td style="text-align: center;">{{doc.numDoc}}</td>
                                                                                <td style="text-align: center;">{{doc.correl}}</td>
                                                                                <td style="text-align: left;">{{doc.cuenta}}</td>
                                                                                <td style="text-align: left;">{{doc.glosa}}</td>
                                                                                <td style="text-align: right;">{{validarNegativos(doc.monto)}}</td>
                                                                            </tr>
                                                                        </tbody>
                                                                        <tfoot>
                                                                            <tr>
                                                                                <td
                                                                                :colspan="tipoOrdenSelected.id == 1 ? 8 : 
                                                                                    tipoOrdenSelected.id == 2 ? 5 : 
                                                                                    tipoOrdenSelected.id == 2 ? 7 : 1">
                                                                                Total</td>
                                                                                <!--<td v-if="tipoOrdenSelected.id == 2" colspan="5">Total</td>-->
                                                                                <td style="text-align:right;">{{validarNegativos(cv.totalGeneralMonto)}}</td> 
                                                                            </tr>
                                                                        </tfoot>
                                                                    </table>
                                                                </CCardBody>
                                                            </div>
                                                        </CCollapse>
                                                    </CCard>
                                                </CCol>
                                            </CRow>
                                        </CCol>
                                    </CRow>
                                </div>
                            </CCol>
                        </CRow>
                        
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
        
        
    </div>
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
    import Vue from 'vue'
    import cons from '@/const'
    import apiUrls from '@/views/utils/apiUrls'
    import axios from "axios"

    import ActualizarEmpresas from "@/views/utils/ActualizarEmpresas";

    import Multiselect from 'vue-multiselect'

    import VueModal from '@kouts/vue-modal';
    import '@kouts/vue-modal/dist/vue-modal.css';
    
    import { freeSet } from '@coreui/icons'

    import moment from 'moment'
    import { format, formatDistance, formatRelative, subDays } from 'date-fns'
    import es from 'date-fns/locale/es'
    window.locale = 'es'

    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';

    export default {
        name: 'CompraVenta',
        freeSet,
        components: {
            Multiselect,
            'Modal': VueModal,
            ActualizarEmpresas,
            DatePicker       
        },
        data () {
            return {
                urlBase: cons.port+"://" + cons.ipServer,
                
                tokenLogin: JSON.parse(this.$cookie.get('userLogginToken')).tokenUsuario,
                tipoUserAdmin: JSON.parse(this.$cookie.get('userLogginToken')).tipoUsuario,
                idCliente: JSON.parse(this.$cookie.get('userLogginToken')).idCliente,
                                
                langPicker: cons.langPicker,
                

                columnasContabilizacion: [],
                compraVentaSelected: null,
                empresasInfo: [],
                empSelected: null,
                periodosEmpresaSelected: null,
                
                anioSelected: null,
                anioObjSelected: null,

                mesSelected: null,
                mesObjSelected: null,

                comprasVentas: [
                    {id: 2, nombre: 'Compras'}, 
                    {id: 1, nombre: 'Ventas'}
                ],

                tipoCuenta: [
                    {id: 1, nombre: 'Contra Cuenta'}, 
                    {id: 2, nombre: 'Documentos'}, 
                ],
                tiposOrden: [
                    {id: 1, nombre: 'Cuenta Contable'}, 
                    {id: 2, nombre: 'Documentos'}, 
                    {id: 3, nombre: 'Centro Resultado'}
                ],

                tipoCtaSelected: {id: 1, nombre: 'Contra Cuenta'},
                tipoOrdenSelected: {id: 1, nombre: 'Cuenta Contable'},
                
                configNumeros: {
                    symbol: '',
                    thousandsSeparator: '.',
                    fractionCount: 2,
                    fractionSeparator: ',',
                    symbolPosition: 'back', 
                    symbolSpacing: false,
                    avoidEmptyDecimals: undefined,
                },
                
                /*Filtros*/
                filtrosCR: null,
                filtrosCRSelected: null,
                cuentasFiltro: null,
                cuentasSelected: null,
                numDocs: null,
                numDocsSelected: null,


                filtrosEmpresa: [],
                filtrosSelected: null,
                ruts: [],
                rutSelected: null,

                isLoadingTrabajadores: false,
                isLoadingPeriodos: false,
                isLoadingFiltros: false,
                isLoadingEmpresasInfo: false,
                isLoadingInfoCV: false,
                isLoadingCuentasFiltro: false,
                isLoadingCRFiltro: false,
                isLoadingNumDocFiltro: false,

                cvInfo: [],
                totales: null,
                


            }
        },
        created: function(){
            
        },
        beforeMount() {
            let cookieUsername = this.$cookie.get('userLogginToken')
            if(cookieUsername === null || cookieUsername === ""){
                this.$router.push("/pages/login");
            }
        },
        mounted(){
            this.obtenerSoloInfoEmpresas()
        },
        methods: {
            seleccionarTipoCta:function(tipoCta){
                this.tipoCtaSelected = tipoCta
                this.validarSeleciones(false)

            },
            seleccionarTipoOrden:function(tipoOrden){
                this.tipoOrdenSelected = tipoOrden
                this.validarSeleciones(false)
            },
            formatearRut: function(rut){
                var rutaFormatear = rut.replace(/^0+|[^0-9kK]+/g, '').toUpperCase();
                let len = rutaFormatear.length - 1;
                if (len > 3) {
                    let dv = rutaFormatear[len];
                    let beforeDv = rutaFormatear.substring(0, len).replace(/\B(?=(\d{3})+(?!\d))/g, '.');
                    rutaFormatear = beforeDv + '-' + dv;
                }
                return rutaFormatear;
            },
            quitarTagFiltro: function(tag){
                this.filtrosSelected.splice(this.filtrosSelected.indexOf(tag), 1)
                this.validarSeleciones(false)
            },
            quitarTag: function(tag){
                this.rutSelected.splice(this.rutSelected.indexOf(tag), 1)
                this.validarSeleciones(false)                                
            },
            quitarTagCuenta: function(tag){
                this.cuentasSelected.splice(this.cuentasSelected.indexOf(tag), 1)
                this.validarSeleciones(false)
            },
            quitarTagCR: function(tag){
                this.filtrosCRSelected.splice(this.filtrosCRSelected.indexOf(tag), 1)
                this.validarSeleciones(false)
            },
            quitarTagNumDoc: function(tag){
                this.numDocsSelected.splice(this.numDocsSelected.indexOf(tag), 1)
                this.validarSeleciones(false)
            },
            actualizarFiltro: function(){
                this.validarSeleciones(false)
            },
            actualizarFiltroRuts: function(){
                this.validarSeleciones(false)
            },
            actualizarFiltroCuentas: function(){
                this.validarSeleciones(false)
            },
            actualizarFiltroCR: function(){
                this.validarSeleciones(false)
            },
            actualizarFiltroNumDoc: function(){
                this.validarSeleciones(false)
            },
            customLabelTrabajador: function(trabajador){
                return `(${this.formatearRut(trabajador.rut)}) ${trabajador.nombre}`
            },
            customLabelCuenta: function(cuenta){
                return `(${cuenta.cuenta}) ${cuenta.nombreCuenta}`
            },
            
            customLabelCR: function(cr){
                return `(${cr.codigo}) ${cr.nombre}`
            },
            disabledAnio: function(date, currentValue){
                let anioAct = format(date, "yyyy", {locale: es})
                let anioNoEncontrado = true
                if(this.periodosEmpresaSelected !== null){
                    anioNoEncontrado = this.periodosEmpresaSelected.find(periodo => periodo.anio == anioAct) === undefined
                }
                return anioNoEncontrado
            },
            disabledMes2: function(date, currentValue){
                let mesAct = format(date, "M", {locale: es})
                let anio = format(date, "yyyy", {locale: es})
                
                let mesNoEncontrado = true
                if(this.anioObjSelected !== null){
                    //anio = this.periodosEmpresaSelected.find(periodo => periodo.anio == anio)
                    if (anio === this.anioObjSelected.anio) {
                        mesNoEncontrado = this.anioObjSelected.meses.find(mes => mes.mesValor == parseInt(mesAct)) === undefined
                    }
                }

                return mesNoEncontrado
            },
            disabledMes: function(date, currentValue){
                
                let mesAct = format(date, "M", {locale: es})
                let anio = format(date, "yyyy", {locale: es})
                
                let mesNoEncontrado = true

                if(this.periodosEmpresaSelected !== null){
                    anio = this.periodosEmpresaSelected.find(periodo => periodo.anio == anio)
                    if (anio !== undefined) {
                        mesNoEncontrado = anio.meses.find(mes => mes.mesValor == parseInt(mesAct)) === undefined
                    }
                }

                return mesNoEncontrado
            },
            validarSeleciones: function(validarFechas){
                if(this.compraVentaSelected !== null && this.empSelected !== null && this.anioSelected !== null){
                    if(validarFechas){
                        let anio = format(this.anioSelected, "yyyy", {locale: es})
                        let mes = null
                        if(this.mesSelected != null){
                            mes = format(this.mesSelected, "M", {locale: es})
                        }
                        if(this.disabledAnio(this.anioSelected)){
                            this.anioSelected = null
                            this.mostrarNotificaciones(2, 'El año seleccionado no se encuentra disponible para la empresa seleccionada')
                            return
                        }
                        if(mes !== null && this.disabledMes2(this.mesSelected)){
                            this.mesSelected = null
                            this.mostrarNotificaciones(2, 'El mes seleccionado no se encuentra disponible para la empresa seleccionada')
                            return
                        }
                    }
                    this.getFiltrosEmpresa()
                    this.getContabilizacion()
                }
            },
            seleccionarAnio: function(){
                this.anioObjSelected = this.periodosEmpresaSelected.find(periodo => periodo.anio == format(this.anioSelected, "yyyy", {locale: es}))
                this.validarSeleciones(true)
            },
            seleccionarMes: function(){
                this.validarSeleciones(true)
            },
            seleccionarCompraVenta: function(compVent){
                this.compraVentaSelected = compVent
                this.validarSeleciones(false)

            },
            seleccionarEmpresa: function(emp){
                this.empSelected = emp
                this.getPeriodosEmpresa()
            },
            getFiltrosEmpresa: function(){
                this.isLoadingFiltros = true
                this.isLoadingTrabajadores = true
                this.isLoadingCuentasFiltro = true
                this.isLoadingCRFiltro = true
                this.isLoadingNumDocFiltro = true
                var url = this.urlBase + apiUrls.contabilidad.compraVenta.getFiltrosContabilizacion
                axios({
                    method: "POST", 
                    "url": url,
                    "data": {
                        "info": {
                            "idCliente": this.idCliente,
                            "idEmpresa": this.empSelected.idEmpresa,
                            "mes": this.mesSelected!==null?format(this.mesSelected, "M", {locale: es}):null,
                            "anio": this.anioSelected!==null?format(this.anioSelected, "yyyy", {locale: es}):null,
                            "compraVenta": this.compraVentaSelected.id,
                            "tipoCta": this.tipoCtaSelected.id,
                            "filtros": this.filtrosSelected,
                            "rutSeleccionados": this.rutSelected,
                            "cuentas": this.cuentasSelected,
                            "cr": this.filtrosCRSelected,
                            "numDoc": this.numDocsSelected,
                        }
                    },
                    "headers": {
                        'Authorization': `${this.tokenLogin}`
                    }
                }).then(result => {
                    //this.mostrarNotificaciones(1, result.data.mensajeGeneral)
                    this.isLoadingFiltros = false
                    this.isLoadingTrabajadores = false
                    this.isLoadingCuentasFiltro = false
                    this.isLoadingCRFiltro = false
                    this.isLoadingNumDocFiltro = false

                    this.filtrosEmpresa = result.data.filtros
                    this.ruts = result.data.filtrosRut
                    this.cuentasFiltro = result.data.cuentas
                    this.filtrosCR = result.data.crEmpresa
                    this.numDocs = result.data.numDoc

                }, error => {
                    this.isLoadingFiltros = false
                    this.isLoadingTrabajadores = false
                    this.isLoadingCuentasFiltro = false
                    this.isLoadingCRFiltro = false
                    this.isLoadingNumDocFiltro = false
                })
                .finally(() => {
                    this.isLoadingFiltros = false
                    this.isLoadingTrabajadores = false
                    this.isLoadingCuentasFiltro = false
                    this.isLoadingCRFiltro = false
                    this.isLoadingNumDocFiltro = false
                });
            },
            getPeriodosEmpresa: function(){
                this.isLoadingPeriodos = true
                var url = this.urlBase + apiUrls.contabilidad.compraVenta.getPeriodosEmpresa
                axios({
                    method: "POST", 
                    "url": url,
                    "data": {
                        "info": {
                            "idCliente": this.idCliente,
                            "idEmpresa": this.empSelected.idEmpresa
                        }
                    },
                    "headers": {
                        'Authorization': `${this.tokenLogin}`
                    }
                }).then(result => {
                    this.isLoadingPeriodos = false
                    this.periodosEmpresaSelected = result.data.periodos
                    this.validarSeleciones(true)
                }, error => {
                    this.isLoadingPeriodos = false
                    //let mensaje = `Problema al obtener empresas , ${error}`
                    //this.textoSinEmpresas = mensaje
                })
                .finally(() => {
                    this.isLoadingPeriodos = false
                });
            },
            getContabilizacion: function(){
                this.isLoadingInfoCV = true
                this.cvInfo = []
                this.totales = null
                var url = this.urlBase + apiUrls.contabilidad.compraVenta.getContabilizacion//"/get_compra_venta_empresa";
                axios({
                    method: "POST", 
                    "url": url,
                    "data": {
                        "info": {
                            "idCliente": this.idCliente,
                            "idEmpresa": this.empSelected.idEmpresa,
                            "mes": this.mesSelected!==null?format(this.mesSelected, "M", {locale: es}):null,
                            "anio": this.anioSelected!==null?format(this.anioSelected, "yyyy", {locale: es}):null,
                            "compraVenta": this.compraVentaSelected.id,
                            "filtros": this.filtrosSelected,
                            "rutSeleccionados": this.rutSelected,
                            "cuentas": this.cuentasSelected,
                            "cr": this.filtrosCRSelected,
                            "numDoc": this.numDocsSelected,
                            "tipoCta": this.tipoCtaSelected.id,
                            "tipoOrden": this.tipoOrdenSelected.id
                        }
                    },
                    "headers": {
                        'Authorization': `${this.tokenLogin}`
                    }
                }).then(result => {
                    this.mostrarNotificaciones(1, result.data.mensajeGeneral)
                    this.cvInfo = result.data.cvInfo
                    this.isLoadingInfoCV = false
                    this.totales = result.data.totales
                    this.asignarColumnas()
                }, error => {
                    this.isLoadingInfoCV = false
                })
                .finally(() => {
                    this.isLoadingInfoCV = false
                });
            },
            

            /*Globales*/
            asignarColumnas: function(){
                if(this.tipoOrdenSelected.id == 1){
                    this.columnasContabilizacion = [
                        { title: 'Fecha', scopedSlots: { customRender: 'fecha' }, width: '7%', align: 'left', ellipsis: true},
                        { title: 'RUT', scopedSlots: { customRender: 'rut' }, width: '12%', align: 'left', ellipsis: false},
                        { title: 'Nombre', scopedSlots: { customRender: 'nombre' }, width: '20%', align: 'left', ellipsis: false},
                        { title: 'Tipo Doc', scopedSlots: { customRender: 'td' }, width: '16%', align: 'center', ellipsis: false},
                        { title: 'Número', scopedSlots: { customRender: 'numero' }, width: '7%', align: 'center', ellipsis: true},
                        { title: 'Correl', scopedSlots:{ customRender: 'correl' }, width: '5%', align: 'center', ellipsis: true},
                        { title: 'CR', scopedSlots:{ customRender: 'cr' }, width: '5%', align: 'left', ellipsis: true},
                        { title: 'Glosa', scopedSlots:{ customRender: 'glosa' }, width: '20%', align: 'left', ellipsis: true},
                        { title: 'Monto', scopedSlots: { customRender: 'mont' }, width: '8%', align: 'right', ellipsis: false}
                        
                        
                    ]
                } else if(this.tipoOrdenSelected.id == 2){
                    this.columnasContabilizacion = [
                        { title: 'Fecha', scopedSlots: { customRender: 'fecha' }, width: '7%', align: 'left', ellipsis: true},
                        { title: 'Cuenta', scopedSlots: { customRender: 'cuenta' }, width: '12%', align: 'left', ellipsis: false},
                        { title: 'Descripcion', scopedSlots:{ customRender: 'descripcion' }, width: '20%', align: 'left', ellipsis: true},
                        { title: 'Glosa', scopedSlots:{ customRender: 'glosa' }, width: '20%', align: 'left', ellipsis: true},
                        { title: 'CR', scopedSlots:{ customRender: 'cr' }, width: '5%', align: 'left', ellipsis: true},
                        { title: 'Monto', scopedSlots: { customRender: 'monto' }, width: '8%', align: 'right', ellipsis: false}
                        
                        
                    ]
                } else if(this.tipoOrdenSelected.id == 3){
                    this.columnasContabilizacion = [
                        { title: 'RUT', scopedSlots: { customRender: 'rut' }, width: '12%', align: 'left', ellipsis: false},
                        { title: 'Nombre', scopedSlots: { customRender: 'nombre' }, width: '20%', align: 'left', ellipsis: false},
                        { title: 'Tipo Doc', scopedSlots: { customRender: 'td' }, width: '16%', align: 'center', ellipsis: false},
                        { title: 'Número', scopedSlots: { customRender: 'numero' }, width: '7%', align: 'center', ellipsis: true},
                        { title: 'Correl', scopedSlots:{ customRender: 'correl' }, width: '5%', align: 'center', ellipsis: true},
                        { title: 'Cuenta', scopedSlots: { customRender: 'cuenta' }, width: '12%', align: 'left', ellipsis: false},
                        { title: 'Glosa', scopedSlots:{ customRender: 'glosa' }, width: '20%', align: 'left', ellipsis: true},
                        { title: 'Monto', scopedSlots: { customRender: 'monto' }, width: '8%', align: 'right', ellipsis: false}
                        
                        
                    ]
                }
            },
            obtenerSoloInfoEmpresas: function(){
                this.isLoadingEmpresasInfo = true
                var url = this.urlBase + apiUrls.general.getEmpresasInfo+"/CONTA" //"/get_empresas_info/CONTA";
                
                axios({
                    method: "GET", 
                    "url": url,
                    "headers": {
                        'Authorization': `${this.tokenLogin}`
                    }
                }).then(result => {
                    
                    this.empresasInfo = result.data;

                }, error => {
                    this.isLoadingEmpresasInfo = false
                    //let mensaje = `Problema al obtener empresas , ${error}`
                    //this.textoSinEmpresas = mensaje
                })
                .finally(() => {
                    this.isLoadingEmpresasInfo = false
                });
            },
            customLabelEmp (empresa) {
                return `(${empresa.codEmpresa}) ${empresa.nombreEmpresa}`;
            },
            
            validarNegativos: function(valor, usarSigno=false){
                let tipoFiltro = usarSigno?'currency':'soloNumeros'
                
                if(parseInt(valor) > 0){
                    return Vue.filter(tipoFiltro)(valor)
                }
                else if(parseInt(valor) == 0){
                    return ''
                } else{
                    return `(${(Vue.filter(tipoFiltro)(valor * -1))})`
                }
            },

            
            
        }
        
    }
</script>

<style>
.mx-input{
    height: 40px !important;
}
</style>